<template>
  <MainLayout
    :content_title="Block_Title"
    :toBlur="notifPopups && notifPopups.toBlur">
    <template v-slot:default>
      <div class="col-12 col-lg-6 col-xl-5 offset-xl-1">
        <BlckTrackDocuments />
      </div>

      <div class="col-12 col-lg-6 col-xl-5">
          <BlckProjectProgress />                               
      </div>
    </template>
    <template v-slot:modals>
      <NotificationsPopups ref="notifPopups" />
      <LegalDocumentReader />
      <SigningDocumentReader />
    </template>
  </MainLayout>
</template>

<script>
import { ref } from 'vue';
import MainLayout from "../layouts/main.layout";
import BlckTrackDocuments from "../components/Suivre-Dossier/BlckTrackDocuments.vue";
import BlckProjectProgress from "../components/Suivre-Dossier/BlckProjectProgress.vue";
import NotificationsPopups from "../components/Popups/NotificationsPopups.vue";
import LegalDocumentReader from '../components/Popups/LegalDocumentReader.vue';
import SigningDocumentReader from '../components/Popups/SigningDocumentReader.vue';

export default {
  page: {
    title: "Home",
    meta: [{ name: "Home page"}],
  },
  components: {
    MainLayout,
    BlckTrackDocuments,
    BlckProjectProgress,
    NotificationsPopups,
    LegalDocumentReader,
    SigningDocumentReader
  },
  data() {
    return {
      Block_Title : "MON DOSSIER"
    };
  },
  setup: () => { 
    const notifPopups = ref();

    return {
      notifPopups
    }
  }
};
</script>
