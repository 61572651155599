<template>
    <div class="border border-color-vio block rounded shadow p-3 mb-5 bg-white rounded">
        <h4 class="text-center content-blocks-title"><strong>{{Block_Title_Suivi}}</strong>
        </h4>
        <hr />
        <p class="block-content-font text-start"> {{Block_Suivre_Doc_Description}} </p>

        <template v-if="legalDocumentsFilter.length">
            <div v-for="legalDoc in legalDocumentsFilter"
                v-bind:key='legalDoc.id'
                class="input-group mb-2 px-2 sv-dossier-input-width doc-row"
                @click="onClickDocument(legalDoc)">
                <template v-if="isValid(legalDoc) != ''">
                    <div v-if="isValid(legalDoc) == 'valide'" class="input-group-prepend">
                        <span class="input-group-text input-icon input-green-background">
                            <img src="@/assets/images/checkbox.svg">
                        </span>
                    </div>
                    <div v-else class="input-group-prepend">
                        <span class="input-group-text input-icon background-color-danger">
                            <img src="@/assets/images/x-solid.svg">
                        </span>
                    </div>
                    <span class="form-control span-filename shadow-none">{{getLabel(legalDoc)}}</span>
                    <span class="input-group-text input-prev-gr icon">
                        <img src="@/assets/images/previsualisation-gr.svg">
                        <span class="tooltip-text">Afficher les documents</span>
                    </span>
                </template>
                <template v-else>
                    <div class="input-group-prepend signature-document-svdossier">
                        <span class="input-group-text input-icon background-color-warn">
                            <img src="@/assets/images/signature.svg">
                        </span>
                    </div>
                    <span class="form-control span-filename span-filename-text-warn">{{getLabel(legalDoc)}}</span>
                </template>
            </div>
        </template>
        <template v-if="documentsSigningFilter.length">
            <div v-for="signingDoc in documentsSigningFilter"
                v-bind:key='signingDoc.id'
                class="input-group mb-2 px-2 sv-dossier-input-width doc-row"
                @click="onClickSigningDocument(signingDoc)">
                <template v-if="isSigned(signingDoc)">
                    <div class="input-group-prepend">
                        <span class="input-group-text input-icon input-green-background">
                            <img src="@/assets/images/checkbox.svg">
                        </span>
                    </div>
                    <span class="form-control span-filename shadow-none">{{getLabel(signingDoc, true)}}</span>
                    <span class="input-group-text input-prev-gr icon">
                        <img src="@/assets/images/previsualisation-gr.svg">
                        <span class="tooltip-text">Afficher les documents</span>
                    </span>
                </template>
                <template v-else-if="isExpired(signingDoc) || isRefused(signingDoc)">
                    <div class="input-group-prepend signature-document-svdossier">
                        <span class="input-group-text input-icon background-color-danger">
                            <img src="@/assets/images/x-solid.svg" >
                        </span>
                    </div>
                    <span class="form-control span-filename span-filename-text-danger"><strong>{{getLabel(signingDoc)}}</strong></span>
                </template>
                <template v-else>
                    <div class="input-group-prepend signature-document-svdossier">
                        <span class="input-group-text input-icon background-color-warn">
                            <img src="@/assets/images/signature.svg" >
                        </span>
                    </div>
                    <span class="form-control span-filename span-filename-text-warn">{{getLabel(signingDoc)}}</span>
                </template>
            </div>
        </template>
        <template v-else-if="documentsSigningFilter.length + legalDocumentsFilter.length == 0">
            <DataLoading :dataLoadingMask="dataLoadingMask"
                :loadingText="Block_Suivre_Doc_Documents_Loading">
                {{Block_Suivre_Doc_Documents_Empty}}
            </DataLoading>
        </template>
    </div>
</template>

<script>
import emitter from '../../events/emitter.js';
import events from '../../constants/events';
import legal_document_variables from '../../constants/legal_document_variables';
import document_signing_status from '../../constants/document_signing_status';
import DataLoading from '../Common/DataLoading';
import DocumentsMixin from '../../mixins/documents';
import DocumentsSetup from '../../mixins/documentsSetup';

export default {
    setup() {
        return DocumentsSetup();
    },
    components: {
        DataLoading
    },
    data() {
        return {
            Block_Title_Suivi: "MES DOCUMENTS REGLEMENTAIRES",
            Block_Suivre_Doc_Description: "Vous pourrez retrouver ici les documents relatifs à l'avancement de votre dossier.",
            Block_Suivre_Doc_Documents_Empty: "Aucun documents",
            Block_Suivre_Doc_Documents_Loading: "Chargements des documents en cours",
            dataLoadingMask: 0
        };
    },
    mixins: [DocumentsMixin],
    methods: {
        /**
         * @description: get all legal documents
         */
        getData() {
            this.dataLoadingMask = 0;
            // get legal documents
            this.dataLoadingMask += 2;
            this.getLegalDocumentListByMainDossier().then()
            .catch((reason) => {
                console.error(reason);
            }).finally(() => {
                this.dataLoadingMask -= 2;
            });
            // get signing documents
            this.dataLoadingMask += 4;
            this.getDocumentSigningByMainDossier().then()
            .catch((reason) => {
                console.error(reason);
            }).finally(() => {
                this.dataLoadingMask -= 4;
            });
        },
        /**
         *
         * @param {*} legalDoc
         * @returns {string}
         */
        getLabel(legalDoc, isSigned = false) {
            if (legalDoc.variable === legal_document_variables.TEMPLATE_JURIDIQUE_LETTRE_RAC_ASSURANCE ) {
                return "Lettre d'informations regroupement de crédits et assurances";
            } else if (legalDoc.variable === legal_document_variables.TEMPLATE_JURIDIQUE_CONVENTION) {
                return "Convention d'honoraires";
            } else if (legalDoc.variable === legal_document_variables.TEMPLATE_JURIDIQUE_CF100C2_PROVISOIRE) {
                return "Recueil des besoins provisoire";
            } else if (legalDoc.variable === legal_document_variables.TEMPLATE_JURIDIQUE_RAC_ASSURANCE_MANDAT) {
                return "Lettres d'informations et Convention d'intermédiation";
            }

            const document = this.seekDocument(legalDoc);
            if (document) {
                if (isSigned) {
                  switch (document.label) {
                    case legal_document_variables.PACK_SIGNATURE_CREDIT:
                      return 'Votre projet crédit';
                    case legal_document_variables.PACK_SIGNATURE_ASSURANCE:
                      return 'Votre projet assurance';
                  }
                }
                return document.label;
            }
            return ''; //legalDoc.variable
        },
        /**
         *
         * @param {*} legalDoc
         * @returns {bool}
         */
        isValid(legalDoc) {
            let state = '';
            if (legalDoc.dateValidation) {
                state = 'valide';
            }
            if (legalDoc.dateRefus) {
                state = 'refus';
            }
            return state;
        },
        /**
         * Determines if a "signing doc" is expired
         *
         * @param {*} signingDoc
         * @returns {bool}
         */
        isExpired(signingDoc) {
            return signingDoc.status === document_signing_status.EXPIRED;
        },
        /**
         * Determines if a "signing doc" is refused
         *
         * @param {*} signingDoc
         * @returns {bool}
         */
        isRefused(signingDoc) {
            return signingDoc.status === document_signing_status.REFUSED;
        },
        /**
         * Determines if a "signing doc" is signed
         *
         * @param {*} signingDoc
         * @returns {bool}
         */
        isSigned(signingDoc) {
            return signingDoc.status === document_signing_status.SIGNED;
        },
        /**
         *
         * @param {*} legalDoc
         */
        onClickDocument(legalDoc) {
            const document = this.seekDocument(legalDoc);
            if (document) {
                emitter.emit(events.document.legal.show, {
                    document,
                    legalDoc,
                    editable: (legalDoc.dateValidation === null) && (legalDoc.dateRefus === null)
                });
            } else {
                console.warn('Document not found for legal document', legalDoc);
            }
        },
        /**
         *
         * @param {*} signingDoc
         */
        onClickSigningDocument(signingDoc) {
            const document = this.seekDocument(signingDoc);
            if (document) {
                emitter.emit(events.document.signing.show, {
                    document,
                    signingDoc
                });
            } else {
                console.warn('Document not found for signing document', signingDoc);
            }
        }
    },
  computed: {
    /**
     * filtered document to have only init document with label
     *
     * @returns {*[]}
     */
    legalDocumentsFilter(){
      return this.legalDocuments.filter(doc => this.seekDocument(doc) &&  this.seekDocument(doc).label)
    },
    /**
     * We will may be need to filter all packsignature
     */
    documentsSigningFilter(){
        return this.documentsSigning
    }
  }
}
</script>

<style scoped lang="scss">
@import '../../assets/css/components/span-filename.scss';
.doc-row{
    cursor: pointer;
}
.input-green-background, .background-color-warn { height: 100%; }

.icon {
    position: relative;
    &:hover {
        img {
            filter: brightness(0.8);
        }

        .tooltip-text {
            visibility: visible;
        }
    }

    .tooltip-text {
        visibility: hidden;
        width: 200px;
        background-color: #F2F2F2;
        filter: brightness(0.9);
        text-align: center;
        border-radius: 50px;
        padding: 5px 0;
        position: absolute;
        z-index: 1;
        bottom: 90%;
        left: 50%;
        margin-left: -100px;
    }
}
</style>
