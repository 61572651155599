<template>
    <div class="popup-notification-signer-position">
        <div class="popup-notification-signer border shadow">
            <div class="row">
                <div>
                    <span class="popup-status-dot">1</span>

                    <span class="signature-popup-close-pos cursor-pointer"
                        @click="closePopup()" ><strong>X<i class="bi bi-x-lg"></i></strong></span>
                </div>
            </div>
            <div class="row">
                <h5 class="text-center popup-notification-signer-title mt-3"
                    id="SignerDocumentsPopupTextLabel">
                    <strong>DOCUMENT MIS A JOUR</strong>
                </h5>
                <p class="popup-notification-signer-content"><i>Un document réglementaire a été mis à jour. Sa prise de connaissance est essentielle à l'avancement de votre projet.</i></p>
            </div>
            <br />
            <div class="row">
                <div>
                    <button type="button" class="button-mini-warning-cus"
                        @click="onSignButtonClick">
                        Signer<div class="arrow"/>
                    </button>
                </div>
            </div>
        </div>
    </div>
</template>

<style scoped lang="scss">
@import '../../assets/css/colors.scss'; 
.popup-notification-signer {
    position: fixed;
    top: 40%;
    left: 0;
    right : 0;
    bottom: 0;
    z-index: 99;
    margin : 0 auto;
    width: 270px;
    height: 320px;

    pointer-events: auto;
    background-color: #fff;
    background-clip: padding-box;
    border-radius: .3rem;
    border-color: #D7E2FE !important;
}

.popup-notification-signer-title{
    margin-top:10px;
    letter-spacing: 3.8px;
    color : #111179;
}

.popup-notification-signer-content{
    margin : 0 auto;
    width:80%;
    margin-top:10px;
    color : #111179;
    font-style: italic;
}

.popup-status-dot {
    margin-top : 10px;
    height: 30px;
    line-height: 30px;
    width: 30px;
    background-color: $color-warn;
    border-radius: 50%;
    display: inline-block;
    text-align: center;
    color: white;
}
</style>

<script>
import { TRACK_FOLDER_PATH } from '../../router/pathes';

export default {
    methods: {
        closePopup() {
            this.$emit('showUpdatedDocumentDocuments', false)
        },
        onSignButtonClick() {
            if (this.$route.path == TRACK_FOLDER_PATH) {
                this.closePopup();
            } else {
                this.$router.push(TRACK_FOLDER_PATH);
            }
        }
    }
}
</script>
