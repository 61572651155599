class Dossier {
    /**
     * 
     * @param Object obj 
     */
    constructor(obj) {
        this.id = parseInt(obj.id);
        this.creationDate = obj.creationDate; // Int
        this.status = obj.status; // string
        this.virtualState = obj.virtualState; // string
        this.historyStatus = obj.historyStatus ?? []; // array
        this.rdv = obj.rdv ?? []; // array
        this.links = obj.links ?? []; // array
    }
}

export default Dossier;
