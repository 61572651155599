import { mapActions } from "vuex";

export default {
    methods: {
        ...mapActions('document', ['getDocumentSigningLink']),
        /**
         * 
         * @param {Object} documentSigning
         * @parma {HTMLElement} target
         */
        openDocumentSigingLink(documentSigning, target) {
            target.disabled = true;
            this.getDocumentSigningLink({ transactionId: documentSigning.transactionId })
                .then((response) => {
                    if (response && response && response.link) {
                        window.open(response.link, "_blank");
                    } else {
                        console.error('Link invalid for document:', documentSigning);
                        alert('Merci de contacter votre commercial');
                    }
                }).finally(() => {
                    target.disabled = false;
                });
        }
    }
}
