const LegalDocumentVariables = {
    TEMPLATE_JURIDIQUE_CONVENTION: 'TEMPLATE_JURIDIQUE_CONVENTION',
    TEMPLATE_JURIDIQUE_ANNEXE: 'TEMPLATE_JURIDIQUE_ANNEXE',
    TEMPLATE_JURIDIQUE_CF100: 'TEMPLATE_JURIDIQUE_CF100',
    TEMPLATE_JURIDIQUE_CF101: 'TEMPLATE_JURIDIQUE_CF101',
    TEMPLATE_JURIDIQUE_CF102: 'TEMPLATE_JURIDIQUE_CF102',
    TEMPLATE_JURIDIQUE_CF100A: 'TEMPLATE_JURIDIQUE_CF100A',
    TEMPLATE_JURIDIQUE_CF100C1: 'TEMPLATE_JURIDIQUE_CF100C1',
    TEMPLATE_JURIDIQUE_CF100C2: 'TEMPLATE_JURIDIQUE_CF100C2',
    TEMPLATE_JURIDIQUE_CF105: 'TEMPLATE_JURIDIQUE_CF105',
    TEMPLATE_JURIDIQUE_CF106: 'TEMPLATE_JURIDIQUE_CF106',
    TEMPLATE_JURIDIQUE_CF107: 'TEMPLATE_JURIDIQUE_CF107',
    TEMPLATE_JURIDIQUE_LETTRE_RAC_ASSURANCE: 'TEMPLATE_JURIDIQUE_LETTRE_RAC_ASSURANCE',
    TEMPLATE_JURIDIQUE_CF100C2_PROVISOIRE: 'CF100C2_PROVISOIRE',
    TEMPLATE_JURIDIQUE_RAC_ASSURANCE_MANDAT: 'TEMPLATE_JURIDIQUE_RAC_ASSURANCE_MANDAT',
    TEMPLATE_JURIDIQUE_CONVENTION_ASSURANCE: 'TEMPLATE_JURIDIQUE_CONVENTION_ASSURANCE',
    PACK_SIGNATURE_CREDIT: 'Pour concrétiser votre projet crédit, signez ici !',
    PACK_SIGNATURE_ASSURANCE: 'Pour concrétiser votre projet assurance, signez ici !',
}

export default LegalDocumentVariables;
