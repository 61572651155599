<template>
    <div v-if="displaySignaturePopup">
        <BlckSignaturePopup @showSignDocuments="onShowSignDocuments"/>
    </div>
    <div v-if="displayUpdatedDocumentPopup">
        <BlckDocumentUpdatedPopup @showUpdatedDocumentDocuments="onShowUpdatedDocumentDocuments"/>
    </div>
</template>

<script>
import BlckSignaturePopup from "./BlckSignaturePopup.vue"
import BlckDocumentUpdatedPopup from "./BlckDocumentUpdatedPopup.vue"

export default {
    components: {
        BlckSignaturePopup,
        BlckDocumentUpdatedPopup
    },
    data() {
        return {
            toBlur : false,
            displaySignaturePopup : false,
            displayUpdatedDocumentPopup: false
        };
    },
    methods: {
        onShowSignDocuments(value){
            this.displaySignaturePopup = value
            this.toBlur = value
        },
        onShowUpdatedDocumentDocuments(value){
            this.displayUpdatedDocumentPopup = value
            this.toBlur = value
        }
    },
}
</script>