<template>
    <div v-if="isVisible" class="SigningDocumentReader">
        <div class="popup-backdrop"></div>
        <div class="popup-wrapper border shadow">
            <div class="row">
                <div>
                    <span class="signature-popup-close-pos cursor-pointer"
                        @click="closePopup()"><strong>X<i class="bi bi-x-lg"></i></strong></span>
                </div>
            </div>
            <div class="row mt-4 pdf-container">
                <p class="popup-content">
                    <pdf-wrapper
                        :dataLoadingMask='dataLoadingMask'
                        :downloadFileName='document.label'
                        :pdfContent='pdfContent'></pdf-wrapper>
                </p>
            </div>
            <div v-if="signingDoc && signingInProgress(signingDoc.status)">
                <button type="button" class="button-mini-warning-cus"
                    @click="onSignateButtonClick($event, signingDoc)">{{ Block_Signate }}
                </button>
            </div>
            <div v-if="signingDoc && signingRefused(signingDoc.status)">
                <div> 
                    {{intituleClient}} {{ nameClient }}, cette proposition d’assurance de prêt vous a été adressée pour signature électronique en date du {{ formatDate(signingDoc.dateSend)}}.
                    <br/> Vous l’avez refusée en date du {{ formatDate(signingDoc.dateRefusal) }}.
                    <br/> Pour plus d’informations, veuillez contacter votre conseiller. 
                </div>
            </div>
        </div>
  </div>
</template>

<script>
import { computed, ref } from "vue";
import { mapActions, useStore } from "vuex";
import { base64toURL } from '../../utils/file.js';
import emitter from '../../events/emitter.js';
import events from '../../constants/events';
import openDocumentSigingLink from "../../mixins/openDocumentSigingLink";
import PdfWrapper from "../Common/PdfWrapper";
import document_signing_status from '../../constants/document_signing_status';
import Client from '../../models/client';
import store from "@/store";

export default {
    components: {
        PdfWrapper
    },
    setup() {
        const store = useStore();

        const mainClient = computed(() => store.getters[`client/getMainClient`]);
        const nameClient = ref('');
        const intituleClient = ref('');

        return {
            mainClient,
            nameClient,
            intituleClient
        }
    },
    data() {
        return {
            Block_Signate: "Signer",
            Block_Next_Button: "Suivant",
            dataLoadingMask: 0,
            editable: false,
            isVisible: false,
            document: null,
            signingDoc: null,
            pdfContent: null // the content of the pdf to display
        }
    },
    async mounted() {
        this.waitForNameClientNotNull();
        emitter.on(events.document.signing.show, ({ document, signingDoc, editable }) => {
            this.isVisible = true;
            this.document = document;
            this.signingDoc = signingDoc;
            this.editable = editable ? editable : false;
            this.displayDocument();
        });
        emitter.on(events.document.signing.hide, () => {
            this.isVisible = false;
            this.reset();
        });
    },
    unmounted() {
        emitter.off(events.document.signing.show);
        emitter.off(events.document.signing.hide);
    },
    methods: {
        ...mapActions('document', ['getDocumentContent']),
        ...mapActions('client', ['getMainClient']),
        closePopup() {
            emitter.emit(events.document.signing.hide);
        },
        /**
         *
         * @param {*} _ event
         * @param {*} document SigningDocument
         */
        onSignateButtonClick(event, document) {
            const target = event.currentTarget;
            this.openDocumentSigingLink(document, target);
        },
        displayDocument() {
            this.getDocumentContent(this.document.id).then((data) => {
                if (data) {
                    const url = base64toURL(data, this.document.file.mimeType || '');
                    //const url = 'data:application/pdf;base64,' + data;
                    this.pdfContent = url;
                }
            }).finally(() => {
                this.dataLoadingMask = 0;
            });
            this.dataLoadingMask = 1;
        },
        reset() {
            this.document = null;
            this.signingDoc = null;
            this.pdfContent = null;
        },
        signingInProgress(status) {
          return status === document_signing_status.PROGRESS
        },
        signingRefused(status) {
          return status === document_signing_status.REFUSED
        },
        formatDate(date) {
            return new Date(date * 1000).toLocaleDateString('fr-FR');
        },
        async waitForNameClientNotNull() {
            if (!this.mainClient || this.nameClient === null) {
                await this.getMainClient().then((client) => {
                    store.commit("setUserFullname", (new Client(client)).getFullname());
                    this.nameClient = (new Client(client)).lastname;
                    this.intituleClient = (new Client(client)).title;
                });
                // Appel récursif pour continuer la vérification jusqu'à ce que this.nameClient ne soit plus null
                await this.waitForNameClientNotNull.call(this);
            }
        }
    },
    mixins: [openDocumentSigingLink]
}
</script>

<style scoped lang="scss">
.popup-wrapper {
    top: 1vh;
    height: 98vh;
    width: 98vw;
    overflow: hidden;

    display: flex;
    flex-direction: column;

    .pdf-container {
        flex-grow: 2;
    }
}

.popup-content{
    width: 98%;
    max-height: 100%;
    overflow-x: hidden;
    overflow-y: auto;
}
</style>
