<template>
    <div class="border border-color-vio block rounded shadow p-3 mb-5 bg-white rounded">
        <h4 class=" text-center content-blocks-title"><strong>{{Block_Title}}</strong></h4>
        <hr />
        <BlckProjectProgressStep
            v-for="(Step, key) in Steps"
            v-bind:key="key"
            v-bind:step="Step"
            v-bind:isCurrent="Step.name == CurrentStatut"
            v-bind:isLast="Steps.length == Step.index"
            v-bind:nbTotalSteps="Steps.length" />
    </div>
</template>

<script>
import { computed } from "vue";
import { useStore } from "vuex";
//import appointement_type from "../../constants/appointment_type";
import { isRefused, map, steps } from '../../mapper/TimelineStatusMapper';
import formatDateMixin from '../../mixins/formatDateMixins';
//import Appointment from '../../models/appointment';
import Dossier from '../../models/dossier';
import BlckProjectProgressStep from './BlckProjectProgressStep.vue';
import getMainDossierAndTimelineStatusRequest from '../../services/graphql/requests/getMainDossierAndTimelineStatusRequest';
import { replaceVars } from '../../utils/string.js';

export default {
    components: {
        BlckProjectProgressStep
    },
    setup() {
        const store = useStore();

        const getTimelineStatus = computed(() => store.getters[`timeline/getTimelineStatus`]);

        return {
            getTimelineStatus,
        }
    },
    data() {
        return {
            Block_Title : "AVANCEMENT DE MON PROJET",
            Steps: this.mapStatus(),
            CurrentStatut: null,
            dossier: null
        };
    },
    methods: {
        /**
         * Fetch timeline status
         */
        getData() {
            getMainDossierAndTimelineStatusRequest().then((data) => {
                this.dossier = new Dossier(data.dossier);
                this.whenTimelineStatusChange(data.timelineStatus);
            }).catch((reason) => {
                console.error(reason);
            });
        },
        fnMapStatus(status) {
            let step = map(status);
            step.text = replaceVars(step.text);
            step.hint = replaceVars(step.hint);
            /* Peux servir d'exemple si besoin d'une étape avec texte à remplacer
            if (status === status_etude) {
                if (this.dossier && this.dossier.rdv && this.dossier.rdv.length)  {
                    // TW-3998 : display only date if appointment 'à la distance'
                    const appointment = new Appointment(this.dossier.rdv[0]);
                    let dateHeure = this.formatDateString(this.dossier.rdv[0].dateStart);
                    if (appointment.type === appointement_type.TEL_PLATEFORME) {
                        dateHeure = dateHeure.split(' ')[0];
                    }
                    step.text = step.text.replace("{{ date_heure_rdv }}", `${dateHeure}`);
                } else {
                    step.text = "";
                }
            }
            */
            return step;
        },
        mapStatus() {
            return steps.map(this.fnMapStatus);
        },
        /**
         * @param String status
         */
        whenTimelineStatusChange(status) {
            if (!status) {
                return;
            }
            this.CurrentStatut = status;
            if (isRefused(status)) {
                this.Steps = this.Steps.slice(0, 3);
                this.Steps.push(this.fnMapStatus(status));
            } else {
                this.Steps = this.mapStatus();
            }
        }
    },
    mixins: [formatDateMixin],
    mounted() {
        this.whenTimelineStatusChange(this.getTimelineStatus);
        this.getData();
    }
}
</script>
