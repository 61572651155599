<template>
    <div class="popup-notification-signer-position">
        <div class="popup-notification-signer border shadow">
            <div class="row">
                <div>
                    <span class="notification-status-dot-red-signature"
                        id="basic-addon1"><img src="@/assets/images/signature.svg" class="mt-1"></span>

                    <span class="signature-popup-close-pos cursor-pointer"
                     @click="closePopup()" ><strong>X<i class="bi bi-x-lg"></i></strong></span>
                </div>
            </div>
            <div class="row">
                <h5 class="text-center popup-notification-signer-title mt-3"
                    id="SignerDocumentsPopupTextLabel">
                    <strong>SIGNATURE</strong>
                </h5>
                <p class="popup-notification-signer-content"><i>Un ou
                    plusieurs documents nécéssitent votre signature.</i></p>
            </div>
            <br />
            <div class="row">
                <div>
                    <button type="button" class="button-mini-warning-cus"
                        @click="onSignButtonClick">
                        Signer<div class="arrow"/>
                    </button>
                </div>
            </div>
        </div>
    </div>
</template>

<style scoped>
.popup-notification-signer {
    position: fixed;
    top: 40%;
    left: 0;
    right : 0;
    bottom: 0;
    z-index: 99;
    margin : 0 auto;
    width: 270px;
    height: 230px;

    pointer-events: auto;
    background-color: #fff;
    background-clip: padding-box;
    border-radius: 10px;
    border-color: #D7E2FE !important;
}

.popup-notification-signer-title{
    margin-top:10px;
    letter-spacing: 3.8px;
    color : #111179;
}

.popup-notification-signer-content{
    margin : 0 auto;
    width:80%;
    margin-top:10px;
    color : #111179;
    font-size: 15px;
}
</style>

<script>
import { TRACK_FOLDER_PATH } from '../../router/pathes';

export default {
    methods: {
        closePopup(){
            this.$emit('showSignDocuments', false)
        },
        onSignButtonClick() {
            if (this.$route.path == TRACK_FOLDER_PATH) {
                this.closePopup();
            } else {
                this.$router.push(TRACK_FOLDER_PATH);
            }
        }
    }
}
</script>
