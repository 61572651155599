import { send } from '../builder/multiquery.client';
import { getDossierAuthorization } from '../../api/dossier/dossier.authorizations';
import { getTimelineStatusAuthorization } from '../../api/timeline/timeline.authorizations';
import { Query } from '../builder/query'; 
import { getDossierQuery } from '../queries/dossier.queries';
import { getTimelineStatusQuery } from '../queries/timeline.queries';
import { getInstance } from '../../../auth/authWrapper';
import store from '../../../store';
import { SET_TIMELINE_STATUS } from '../../../store/mutations/timeline.types';

export default function getMainDossierAndTimelineStatusRequest() {
    const dossierId = getInstance().getUserDossierId();
    return send({
        queries: [
            new Query(getDossierQuery(dossierId), 'dossier'),
            new Query(getTimelineStatusQuery(dossierId), 'timelineStatus')
        ],
        authorizations: [
            getDossierAuthorization(),
            getTimelineStatusAuthorization()
        ],
        headers: {
            // TODO: remove after merge of #3262
            'X-AUTH-ROLES': 'DOSSIER_GET_ONE'
        },
        responseTransformer(response) {
            store._modules.root._children.timeline.context.commit(SET_TIMELINE_STATUS, response.data.data.timelineStatus);
        }
    });
}
