<template>
    <div class="modal-hint-content rounded shadow" v-bind:class="{ hide_element : isHintModalHidden }">
        <div class="row">
            <div class="col">
                <div class="text-start p-4">
                    <p class="lampe-popup-content my-0" v-html="text"></p>
                </div>
            </div>
        </div>
    </div>
</template>

<script>
export default {
    props: {
        isHintModalHidden: Boolean,
        text: String
    }
}
</script>

<style scoped>
  .modal-hint-content {
      position: absolute;
      background-color: #fff;
      right: 0px;
      width: 400px;
      max-width: 70%;
      z-index: 99;
  }
</style>
