<template>
    <div v-if="isVisible" class="LegalDocumentReader">
        <div class="popup-backdrop"></div>
        <div class="popup-wrapper border shadow d-flex">
            <div class="row">
                <div>
                    <span class="signature-popup-close-pos cursor-pointer"
                        @click="closePopup()"><strong>X<i class="bi bi-x-lg"></i></strong></span>
                </div>
            </div>
            <div class="row mt-4 pdf-container">
                <div :class='[
                        "popup-content",
                        editable ? "editable" : "",
                        isConventionHonoraires ? "convention-honoraires" : ""
                    ]'>
                    <pdf-wrapper
                        :dataLoadingMask='dataLoadingMask'
                        :downloadFileName='document.label'
                        :pdfContent='pdfContent'></pdf-wrapper>
                </div>
            </div>
            <div v-if="editable">
                <div v-if="mustBeChecked">
                    <input type="checkbox" id="legal_accept"
                        @change="onChange" v-model="legalAccept" />
                    <label for="legal_accept">{{ getLegalAcceptLabel() }}</label>
                </div>

                <div v-if="isConventionHonoraires" class="legal-convention-block">
                    <div>{{ Block_Convention }}</div>
                    <div>
                        <div class="d-inline-block px-1">
                            <input type="radio" id="consent_accept" name="consent" value="1"
                                @change="onChange" v-model="consent" />
                            <label for="consent_accept">&nbsp;{{ Block_Convention_Accept }}</label>
                        </div>
                        <div class="d-inline-block px-1">
                            <input type="radio" id="consent_refuse" name="consent" value="0"
                                @change="onChange" v-model="consent" />
                            <label for="consent_refuse">&nbsp;{{ Block_Convention_Refuse }}</label>
                        </div>
                    </div>
                </div>

                <div v-if="isConventionHonoraires">
                    <div>{{ Block_Convention_Sms }}</div>
                    <div>
                        <div class="d-inline-block px-1">
                            <input type="radio" id="consent_sms_accept" name="consent_sms" value="1"
                                @change="onChange" v-model="consent_sms" />
                            <label for="consent_sms_accept">&nbsp;{{ Block_Convention_Accept }}</label>
                        </div>
                        <div class="d-inline-block px-1">
                            <input type="radio" id="consent_refuse" name="consent_sms" value="0"
                                @change="onChange" v-model="consent_sms" />
                            <label for="consent_sms_refuse">&nbsp;{{ Block_Convention_Refuse }}</label>
                        </div>
                    </div>
                </div>

                <div v-if="isConventionHonoraires">
                    <div>{{ Block_Convention_Email }}</div>
                    <div>
                        <div class="d-inline-block px-1">
                            <input type="radio" id="consent_email_accept" name="consent_email" value="1"
                                @change="onChange" v-model="consent_email" />
                            <label for="consent_email_accept">&nbsp;{{ Block_Convention_Accept }}</label>
                        </div>
                        <div class="d-inline-block px-1">
                            <input type="radio" id="consent_email_refuse" name="consent_email" value="0"
                                @change="onChange" v-model="consent_email" />
                            <label for="consent_email_refuse">&nbsp;{{ Block_Convention_Refuse }}</label>
                        </div>
                    </div>
                </div>

                <div v-if="isAcceptRefusBlock">
                    <div>{{ Block_ConventionInterAssurance }}</div>
                    <button type="button" class="button-mini-warning-cus"
                        :disabled="disabled"
                        @click="onConfirmButtonClick">{{ Block_Convention_Accept }}
                      <div class="arrow-white" />
                    </button>
                    <button type="button" class="button-mini-warning-cus"
                        :disabled="disabled"
                        @click="onConfirmButtonClick(false)">{{ Block_Convention_Refuse }}
                      <div class="arrow-white" />
                    </button>
                </div>
                <div v-else>
                    <button type="button" class="button-mini-warning-cus"
                        :disabled="disabled"
                        @click="onConfirmButtonClick">{{ Block_Validate }}
                      <div class="arrow-white" />
                    </button>
                </div>
            </div>
        </div>
  </div>
</template>

<script>
import { mapActions } from "vuex";
import emitter from '../../events/emitter.js';
import legal_document_variables from "../../constants/legal_document_variables";
import events from '../../constants/events';
import PdfWrapper from "../Common/PdfWrapper";
import Client from '../../models/client';
import { base64toURL } from '../../utils/file.js';
import getProfileRequest from '../../services/graphql/requests/getProfileRequest';

export default {
    components: {
        PdfWrapper
    },
    data() {
        return {
            Block_Checkbox_Convention: 'Je reconnais avoir lu et j\'accepte la convention d\'honoraires.',
            Block_Checkbox_LettreRacAss: 'Je reconnais avoir lu et j\'accepte la lettre d\'informations crédit/assurance.',
            Block_Checkbox_ConventionLettreRac: 'Je reconnais avoir lu et j\'accepte la lettre d\'informations et la convention d\'intermédiation.',
            Block_Checkbox_ConventionLettreRac_Co: 'Nous reconnaissons avoir lu et nous acceptons les termes de la lettre d\'informations et de la convention d\'intermédiation.',
            Block_ConventionInterAssurance: 'Je reconnais avoir lu la convention d\'intermédiation et accepte que l\'Intermédiaire recherche et me propose une assurance de prêt',
            Block_Convention: "J’accepte que mes données personnelles soient utilisées par vos partenaires commerciaux à des fins de prospection commerciale : ",
            Block_Convention_Sms: "J'accepte d'être contacté par sms pour de la prospection commerciale",
            Block_Convention_Email: "J'accepte d'être contacté par mail pour de la prospection commerciale",
            Block_Convention_Accept: "oui",
            Block_Convention_Refuse: "non",
            Block_Validate: "Valider",
            Block_Accepter: "Accepter",
            Block_Refuser: "Refuser",
            Block_Next_Button: "Suivant",
            consent: "",
            consent_email: "",
            consent_sms: "",
            dataLoadingMask: 0,
            disabled: true,
            editable: false,
            isVisible: false,
            document: null,
            legalAccept: "",
            legalDoc: null,
            pdfContent: null, // the content of the pdf to display
            Loaner : new Client({}),
            CoLoaner : new Client({id : 0})
        }
    },
    mounted() {
        emitter.on(events.document.legal.show, ({ document, legalDoc, editable }) => {
            this.isVisible = true;
            this.document = document;
            this.legalDoc = legalDoc;
            this.editable = editable ? editable : false;
            this.disabled = this.editable && this.mustBeChecked;
            this.displayDocument();
        });
        emitter.on(events.document.legal.hide, () => {
            this.isVisible = false;
            this.reset();
        });
        getProfileRequest().then((data) => {
                if (data.client) {
                    this.Loaner = new Client(data.client);
                }
                if (data.dossier && data.dossier.coLoaner) {
                    this.CoLoaner = new Client(data.dossier.coLoaner);
                }
        }).catch((reason) => {
            console.error(reason);
        });
    },
    unmounted() {
        emitter.off(events.document.legal.show);
        emitter.off(events.document.legal.hide);
    },
    computed: {
        /**
         * @returns {bool}
         */
        isConventionHonoraires() {
            return this.legalDoc.variable === legal_document_variables.TEMPLATE_JURIDIQUE_CONVENTION;
        },
        /**
         * @returns {bool}
         */
         isAcceptRefusBlock() {
            return this.legalDoc.variable === legal_document_variables.TEMPLATE_JURIDIQUE_CONVENTION_ASSURANCE;
        },
        /**
         * @returns {bool}
         */
        mustBeChecked() {
            return [
                legal_document_variables.TEMPLATE_JURIDIQUE_LETTRE_RAC_ASSURANCE,
                legal_document_variables.TEMPLATE_JURIDIQUE_CONVENTION,
                legal_document_variables.TEMPLATE_JURIDIQUE_RAC_ASSURANCE_MANDAT,
            ].includes(this.legalDoc.variable);
        }
    },
    methods: {
        ...mapActions('client', ['setClientContactAccept', 'setClientPartnersAccept']),
        ...mapActions('document', ['getDocumentContent', 'patchLegalDocument']),
        /**
         * @returns {string}
         */
        getLegalAcceptLabel() {
            
            switch (this.legalDoc.variable) {
                case legal_document_variables.TEMPLATE_JURIDIQUE_LETTRE_RAC_ASSURANCE:
                    return this.Block_Checkbox_LettreRacAss;

                case legal_document_variables.TEMPLATE_JURIDIQUE_CONVENTION:
                    return this.Block_Checkbox_Convention;
                
                case legal_document_variables.TEMPLATE_JURIDIQUE_RAC_ASSURANCE_MANDAT:
                    var readerPhrase = '';
                    if (this.CoLoaner.id != 0) {
                        readerPhrase = this.Block_Checkbox_ConventionLettreRac_Co;
                    } else {
                        readerPhrase = this.Block_Checkbox_ConventionLettreRac;
                    }
                    return readerPhrase;
                default:
                    return '';
            }
        },
        closePopup() {
            emitter.emit(events.document.legal.hide);
        },
        /**
         *
         */
        onConfirmButtonClick(valid = true) {
            this.disabled = true;

            if (this.isConventionHonoraires) {
                this.setClientPartnersAccept({ acceptPartners: this.consent == "1" }).then();
                this.updateComPreferences();
            }

            this.patchLegalDocument({
                id: this.document.id,
                dateConsultation: this.legalDoc.dateConsultation,
                dateProvision: this.legalDoc.dateProvision,
                dateValidation: valid ? parseInt((new Date()).getTime() / 1000, 10) : null,
                dateRefus: valid ? null : parseInt((new Date()).getTime() / 1000, 10),
            }).then(() => {
                emitter.emit(events.notifications.refresh);
                this.closePopup();
            }).catch(() => {
                this.disabled = false;
            });
        },
        onChange() {
            let disabled = false;

            if (this.legalAccept !== true) {
                disabled = true;
            }
            if (this.isConventionHonoraires) {
                if (this.consent == "") {
                    disabled = true;
                }
            }

            this.disabled = disabled;
        },
        displayDocument() {
            this.getDocumentContent(this.document.id).then((data) => {
                if (data) {
                    const url = base64toURL(data, this.document?.file.mimeType || '');
                    this.pdfContent = url;
                }
            }).finally(() => {
                this.dataLoadingMask = 0;
                this.saveConsultation();
            });
            this.dataLoadingMask = 1;
        },
        reset() {
            this.consent = "";
            this.consent_email = "";
            this.consent_sms = "";
            this.disabled = true;
            this.document = null;
            this.legalDoc = null;
            this.legalAccept = "";
            this.pdfContent = null;
        },
        saveConsultation() {
            if (this.legalDoc && this.legalDoc.dateConsultation === null) {
                this.patchLegalDocument({
                    id: this.document.id,
                    dateConsultation: parseInt((new Date()).getTime() / 1000, 10),
                    dateProvision: this.legalDoc.dateProvision,
                    dateValidation: this.legalDoc.dateValidation,
                    dateRefus: this.legalDoc.dateRefus,
                }).then((data) => {
                    this.legalDoc = data;
                });
            }
        },
        /**
         * Send com preferences to API
         */
        updateComPreferences() {
            //do send preferences when backend is rdy.
            const acceptEmailing = this.consent_email == "1";
            const acceptSms = this.consent_sms == "1";
            this.setClientContactAccept({acceptEmailing, acceptSms}).then();
        }
    }
}
</script>

<style scoped lang="scss">
.popup-wrapper {
    top: 1vh;
    height: 98vh;
    width: 98vw;
    overflow: hidden;
    display: flex;
    flex-direction: column;

    .pdf-container {
        flex-grow: 2;
    }
}

.popup-content{
    width: 98%;
    max-height: 100%;
    overflow-x: hidden;
    overflow-y: auto;
}

div.legal-convention-block{
    margin-bottom: 1rem;
    margin-top: 1rem;
}

@media only screen and (max-device-width: 768px) {
    .popup-wrapper {
        height: 90vh;
    }
}
</style>
